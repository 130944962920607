import { httpClient } from './httpClient'
import { AxiosResponse } from 'axios'
import useSessions from '@/composables/useSessions'

const routePayments = 'payment-method'

interface CardInfo {
  CardHolderName: string;
  CardSessionHref: string;
}

const getPaymentMethods = async (): Promise<AxiosResponse> => {
  const { userSession } = useSessions()
  httpClient.defaults.headers.Authorization = `Bearer ${userSession.value?.AccessToken}`
  return (await httpClient.get<AxiosResponse>(routePayments))
}

const saveCardToBuyerAccount = async (cardInfo: CardInfo): Promise<AxiosResponse> => {
  const { userSession } = useSessions()
  httpClient.defaults.headers.Authorization = `Bearer ${userSession.value?.AccessToken}`
  httpClient.defaults.headers.Accept = 'application/vnd.expressway.payments-v2+json'
  return (await httpClient.post<AxiosResponse>(routePayments, cardInfo)).data
}

const deleteCardFromBuyerAccount = async (): Promise<AxiosResponse> => {
  const { userSession } = useSessions()
  httpClient.defaults.headers.Authorization = `Bearer ${userSession.value?.AccessToken}`
  return (await httpClient.delete<AxiosResponse>(routePayments))
}

export { getPaymentMethods, saveCardToBuyerAccount, deleteCardFromBuyerAccount }
